import React from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';

import CardWithIcon from './CardWithIcon';

const MonthlyRevenue = ({ value }) => {
  return (
    <CardWithIcon
      icon={DollarIcon}
      title="Monthly Incomes"
      subtitle={`₪${value}`}
    />
  );
};

export default MonthlyRevenue;