import React from 'react';
import {
  Edit,
  TextInput,
  TabbedForm,
  FileInput,
  FormTab,
  NumberInput,
  ImageField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import {
  ListItemText,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { COACH, SPECIALITY } from '../resources';
import { MonthNames } from '../dataProvider/utils';

const OrganisationTitle = ({ record }) => {
  return <span>Organisation: {record ? record.name : ''}</span>;
};

const percentageParser = v => {
  if (Number(v) < 1) return 1
  if (Number(v) > 100) return 100
  return Number(v)
}

const EarningsList = ({ record }) => {
  const totalMonth = {};
  if (record.earnings?.length) {
    record.earnings.forEach((earning) => {
      if (totalMonth[MonthNames[earning.month]]) {
        totalMonth[MonthNames[earning.month]].organisationIncome += +earning.amount;
        totalMonth[MonthNames[earning.month]].coachIncome += +earning.coachFee;
        totalMonth[MonthNames[earning.month]].adminIncome += +earning.adminFee;
        totalMonth[MonthNames[earning.month]].total += +earning.adminFee + +earning.amount + +earning.coachFee;
      } else {
        totalMonth[MonthNames[earning.month]] = {
          coachIncome: +earning.coachFee,
          adminIncome: +earning.adminFee,
          organisationIncome: +earning.amount,
          total: +earning.adminFee + +earning.amount + +earning.coachFee,
        };
      }
    });
  }
  if (Object.keys(totalMonth)?.length) {
    return (
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                <TableCell>Total Income</TableCell>
                <TableCell>Organisation Income</TableCell>
                <TableCell>Coach Income</TableCell>
                <TableCell>System Income</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(totalMonth).map((key, i) => {
                return (
                  <TableRow hover key={i}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{totalMonth[key].total}</TableCell>
                    <TableCell>{totalMonth[key].organisationIncome}</TableCell>
                    <TableCell>{totalMonth[key].coachIncome}</TableCell>
                    <TableCell>{totalMonth[key].adminIncome}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
  return <ListItemText>This organisation doesn't have incomes</ListItemText>;
};

export const OrganisationEdit = (props) => (
  <Edit title={<OrganisationTitle />} {...props}>
    <TabbedForm>
      <FormTab label="organisation info">
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="address" />
        <TextInput label="Email" source="user.email" disabled />
        <ReferenceArrayInput reference={SPECIALITY} source="specialityIds">
          <AutocompleteArrayInput label="Specialities" />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference={COACH} source="coachIds" filter={{ status: 'APPROVED' }}>
          <AutocompleteArrayInput label="Coaches" optionText="fullName" />
        </ReferenceArrayInput>
        <FileInput source="image">
          <ImageField source="url" title="filename" />
        </FileInput>
      </FormTab>
      <FormTab label="financial">
        <NumberInput min={1} max={100} parse={percentageParser} source="adminFee" />
        <NumberInput min={1} max={100} parse={percentageParser} source="coachFee" />
        <EarningsList />
      </FormTab>
    </TabbedForm>
  </Edit>
);
