import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  SelectInput,
  NumberInput,
  DateInput
} from 'react-admin';

const CouponTitle = ({ record }) => {
  return <span>Coupon: {record ? record.title : ''}</span>;
};

export const CouponEdit = (props) => (
  <Edit title={<CouponTitle />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" />
      <SelectInput source="discountType" choices={[
        { id: 'AMOUNT', name: 'Amount' },
        { id: 'PERCENTAGE', name: 'Percentage' },
      ]} />
      <NumberInput source="amount" />
      <NumberInput source="available" />
      <DateInput source="validUntil" />
    </SimpleForm>
  </Edit>
);