import React, { useCallback, useMemo, useState } from 'react';
import { Edit, TabbedForm, TextField, EmailField, FormTab, useDataProvider } from 'react-admin';
import {
  ListItemText,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Avatar,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField as MUITextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { cancelProgramByAdminMutation } from '@witness/graphql';
import { calculateRemainingPrice } from '../dataProvider/utils';
import { TRAINEE } from '../resources';

const useStyles = makeStyles((theme) =>
  createStyles({
    removeInputBlock: {
      marginTop: 12,
      display: 'flex',
      flexDirection: 'column',
    },
    removeHint: {
      textAlign: 'center',
    },
    removeInput: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
    },
  }),
);

const TraineeTitle = ({ record }) => {
  return <span>Trainee: {record ? record.fullName : ''}</span>;
};

const TYPE_TEXT = 'CANCEL_PROGRAM';

const ProgramsList = ({ record }) => {
  const [currentProgram, setProgram] = useState(null);
  const [text, setText] = useState('');
  const programPrice = useMemo(() => {
    return calculateRemainingPrice(currentProgram);
  }, [currentProgram]);

  const classes = useStyles();

  const [cancelProgram, { loading }] = useMutation(cancelProgramByAdminMutation);
  const provider = useDataProvider();

  const handleCancel = useCallback(async () => {
    await cancelProgram({
      variables: {
        record: {
          uid: currentProgram?.id,
        },
      },
    });
    await provider.getOne(TRAINEE, { id: record.id });
    setProgram(null);
  }, [cancelProgram, currentProgram, provider, record]);

  if (
    record.programs &&
    record.programs.length &&
    record.programs[0].coach &&
    record.programs[0].trainingTypes
  ) {
    return (
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Create Date</TableCell>
                <TableCell>Program Name</TableCell>
                <TableCell>Program Request Status</TableCell>
                <TableCell>Program Status</TableCell>
                <TableCell>Coupon</TableCell>
                <TableCell>Total Price</TableCell>
                <TableCell>Coach</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.programs.map((program) => {
                let programName = ''
                if (program.coachProgram) {
                  programName = program.coachProgram.programName
                } else if (program.trainingTypes.length) {
                  programName = program.trainingTypes[0].name
                } else {
                  programName = 'Unknown'
                }
                return (
                  <>
                    {
                      program.status !== 'UNPAID' && (

                        <TableRow hover key={program.id}>
                          <TableCell>{program.createdAt.slice(0, 10)}</TableCell>
                          <TableCell>
                            {programName}
                          </TableCell>
                          <TableCell>{program.status}</TableCell>
                          <TableCell>{program.coachProgram && program.coachProgram.status}</TableCell>
                          <TableCell>
                            {(program.code && program.code.digits) ||
                              (program.extensionCoupon && program.extensionCoupon.digits)}
                          </TableCell>
                          <TableCell>
                            {parseInt((program.payments?.[0]?.sum) || (program.monthlySum * program.programLength) / 30)}
                          </TableCell>
                          <TableCell>
                            <Typography component={Link} to={`/coach/${program.coach && program.coach.uid}`}>
                              {program.coach && program.coach.fullName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {program.status === 'APPROVED' && (
                              <Button onClick={() => setProgram(program)} type="button">
                                CANCEL
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={!!currentProgram}
        >
          <DialogTitle id="simple-dialog-title" variant="secondary">
            Removing a program
          </DialogTitle>
          <DialogContent>
            <Typography>You will need to return back ₪{programPrice} to this trainee</Typography>
            <div className={classes.removeInputBlock}>
              <Typography className={classes.removeHint}>Type "{TYPE_TEXT}" to proceed</Typography>
              <MUITextField
                label="Type here"
                value={text}
                onChange={(e) => setText(e.target.value)}
                variant="outlined"
                size="small"
                className={classes.removeInput}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setProgram(null)} type="button">
              CANCEL
            </Button>
            <Button
              onClick={handleCancel}
              type="button"
              color="primary"
              variant="contained"
              disabled={text !== TYPE_TEXT || loading}
            >
              REMOVE
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
  return <ListItemText>This trainee doesn't have programs</ListItemText>;
};

const TraineeAvatar = ({ record }) => {
  return (
    <div>{record.avatar ? <Avatar src={`${record.avatar.url}?size=32x32`} /> : <Avatar />}</div>
  );
};

export const TraineeEdit = (props) => (
  <Edit title={<TraineeTitle />} {...props}>
    <TabbedForm>
      <FormTab label="personal info">
        <TraineeAvatar label="Profile Image" />
        <TextField source="fullName" />
        <EmailField label="Email" source="user.email" />
        <TextField source="age" />
        <TextField source="weight" />
        <TextField source="height" />
        <TextField source="gender" />
      </FormTab>
      <FormTab label="Programs">
        <ProgramsList />
      </FormTab>
    </TabbedForm>
  </Edit>
);
