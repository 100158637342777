import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  TextInput
} from 'react-admin';
import { Avatar } from '@material-ui/core'

const CoachFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const CoachAvatar = ({ record }) => {
  return (
    <div>
      {record.profileImage ? (
        <Avatar src={`${record.profileImage.url}?size=32x32`} />
      ) : (
        <Avatar />
      )}
    </div>
  )
}

export const CoachList = (props) => (
  <List {...props} filters={<CoachFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <CoachAvatar label="Profile" />
      <TextField source="fullName" sortable={false} />
      <EmailField label="Email" source="user.email" sortable={false} />
      <TextField source="phone" sortable={false} />
      <ArrayField source="specialities">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField source="status" sortable={false} />
      <DateField label="Registered" source="createDate" sortable={false} />
    </Datagrid>
  </List>
);
