import * as React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { subDays, addDays } from 'date-fns';

const lastDay = new Date();
const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));
const aMonthAgo = subDays(new Date(), 30);

const dateFormatter = (date) =>
  new Date(date).toLocaleDateString();

const aggregateProgramsByDay = (programs) =>
  programs
    .reduce((acc, curr) => {
      const day = new Date(curr.createdAt).toISOString().slice(0, 10);
      if (!acc[day]) {
        acc[day] = 0;
      }
      acc[day]++;
      return acc;
    }, {});

const getProgramPerDay = (programs) => {
  const daysWithProgram = aggregateProgramsByDay(programs);
  return lastMonthDays.map(date => ({
    date: date.getTime(),
    total: daysWithProgram[new Date(date).toISOString().slice(0, 10)] || 0,
  }));
};

const ProgramChart = ({ programs }) => {
  if (!programs) return null;

  return (
    <Card>
      <CardHeader title="New Program Requests" />
      <CardContent>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={getProgramPerDay(programs)}>
              <defs>
                <linearGradient
                  id="colorUv"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor="#8884d8"
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor="#8884d8"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[
                  addDays(aMonthAgo, 1).getTime(),
                  new Date().getTime(),
                ]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Programs" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                formatter={value =>
                  new Intl.NumberFormat(undefined).format(value)
                }
                labelFormatter={(label) =>
                  dateFormatter(label)
                }
              />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default ProgramChart;