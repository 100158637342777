import { LANGUAGES } from '../services/constants';

export const serializeItem = (item) => {
  const translations = item.translations || [];
  const names = translations.reduce((acc, t) => {
    return {
      ...acc,
      [LANGUAGES[t.lang]]: t.value,
    };
  }, {});

  return {
    ...item,
    ...names,
  };
};

export const translateParams = (params) => {
  const translations = [];

  Object.keys(LANGUAGES)
    .filter(
      (key) =>
        !params.previousData ||
        !params.previousData.translations?.find(
          (old) => old.lang === key && old.value === params.data[LANGUAGES[key]],
        ),
    )
    .forEach((key) => {
      translations.push({ lang: key, value: params.data[LANGUAGES[key]] || '' });
    });

  return translations;
};

export const MonthNames = [
  '0',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const calculateRemainingPrice = (program) => {
  if (!program || !program.monthlySum) {
    console.warn('no program or monthlySum', program?.monthlySum);
    return 0;
  }

  const startDate = new Date();
  const endDate = new Date(program?.coachProgram?.beginningDate);
  endDate.setDate(endDate.getDate() + program.programLength);

  if (isNaN(endDate.getTime())) {
    console.warn(
      'invalid beginningDate or programLength',
      program?.coachProgram?.beginningDate,
      program.programLength,
    );
    return 0;
  }
  const year = endDate.getFullYear() - startDate.getFullYear();
  const month = endDate.getMonth() + year * 12 - startDate.getMonth();

  if (month < 0) {
    console.warn('month count is lower than zero');
    return 0;
  }

  return (month - 1) * program.monthlySum;
};
