import {
  COACH,
  SPECIALITY,
  EXERCISE,
  GOAL,
  TRAININGTYPE,
  CALENDARMESSAGE,
  ICON,
  PAYMENT,
  COUPON,
  EARNING,
  TRAINEE,
  PROGRAM,
  ORGANISATION,
  GROUP,
} from '../resources';
import coachProvider from '../coach/dataProvider';
import groupProvider from '../group/dataProvider';
import traineeProvider from '../trainee/dataProvider';
import programProvider from '../program/dataProvider';
import specialityProvider from '../speciality/dataProvider';
import exerciseProvider from '../exercise/dataProvider';
import goalProvider from '../goal/dataProvider';
import trainingTypeProvider from '../training-type/dataProvider';
import calendarMessageProvider from '../calendar-message/dataProvider';
import iconProvider from '../icon/dataProvider';
import paymentProvider from '../payment/dataProvider';
import couponProvider from '../coupon/dataProvider';
import earningProvider from '../earning/dataProvider';
import organisationProvider from '../organisation/dataProvider';

// const dataProvider = {
//   getList:    (resource, params) => Promise,
//   getOne:     (resource, params) => Promise,
//   getMany:    (resource, params) => Promise,
//   getManyReference: (resource, params) => Promise,
//   create:     (resource, params) => Promise,
//   update:     (resource, params) => Promise,
//   updateMany: (resource, params) => Promise,
//   delete:     (resource, params) => Promise,
//   deleteMany: (resource, params) => Promise,
// }

const getProvider = (resource) => {
  switch (resource) {
    case COACH:
      return coachProvider;
    case TRAINEE:
      return traineeProvider;
    case PROGRAM:
      return programProvider;
    case GROUP:
      return groupProvider;
    case SPECIALITY:
      return specialityProvider;
    case EXERCISE:
      return exerciseProvider;
    case GOAL:
      return goalProvider;
    case TRAININGTYPE:
      return trainingTypeProvider;
    case CALENDARMESSAGE:
      return calendarMessageProvider;
    case ICON:
      return iconProvider;
    case PAYMENT:
      return paymentProvider;
    case COUPON:
      return couponProvider;
    case EARNING:
      return earningProvider;
    case ORGANISATION:
      return organisationProvider;
    default:
      throw new Error(`Resource "${resource}" was not found`);
  }
};

export const dataProvider = {
  getList: (resource, params) => {
    const { getList } = getProvider(resource);
    return typeof getList === 'function' ? getList(params) : { data: [], total: 0 };
  },
  getMany: (resource, params) => {
    const { getMany } = getProvider(resource);
    return typeof getMany === 'function' ? getMany(params) : { data: [] };
  },
  getOne: (resource, params) => {
    const { getOne } = getProvider(resource);
    return typeof getOne === 'function' ? getOne(params) : { data: [] };
  },
  create: (resource, params) => {
    const { create } = getProvider(resource);
    return typeof create === 'function' ? create(params) : { data: [] };
  },
  update: (resource, params) => {
    const { update } = getProvider(resource);
    return typeof update === 'function' ? update(params) : { data: params.previousData };
  },
  delete: (resource, params) => {
    const provider = getProvider(resource);
    return typeof provider.delete === 'function' ? provider.delete(params) : { data: [] };
  },
};
