import React from 'react';
import {
  Edit,
  TabbedForm,
  TextInput,
  SelectInput,
  FormTab,
  ImageField,
  ArrayField,
  FileInput,
  Datagrid,
  TextField,
  FileField,
  NumberInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';
import {
  ListItemText,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Avatar,
  TableCell,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import RichTextInput from 'ra-input-rich-text';
import { MonthNames } from '../dataProvider/utils';

const CoachTitle = ({ record }) => {
  return <span>Coach: {record ? record.fullName : ''}</span>;
};

const TraineeList = ({ record }) => {
  let trainees = [];
  if (record.coachPrograms) {
    record.coachPrograms.forEach((coachProgram) => {
      if (coachProgram.trainee) {
        trainees.push(coachProgram.trainee);
      } else if (coachProgram.participants) {
        trainees = [...trainees, ...coachProgram.participants];
      }
    });
    trainees = trainees.filter((trainee) => trainee.programs);
    return (
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Avatar</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Program Name</TableCell>
                <TableCell>Program Length</TableCell>
                <TableCell>Program Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {trainees.map((trainee) => {
                return (
                  <TableRow hover key={trainee.id}>
                    <TableCell width="10px">
                      {trainee.avatar ? (
                        <Avatar src={`${trainee.avatar.url}?size=32x32`} />
                      ) : (
                        <Avatar />
                      )}
                    </TableCell>
                    <TableCell component={Link} to={`/trainee/${trainee.uid}`}>
                      {trainee.fullName}
                    </TableCell>
                    <TableCell>{trainee.programs[0].trainingTypes[0].name}</TableCell>
                    <TableCell>
                      {Math.round(trainee.programs[0].programLength / 30)} Month
                    </TableCell>
                    <TableCell>{trainee.programs[0].type}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
  return <ListItemText>This coach doesn't have trainees</ListItemText>;
};

const EarningsList = ({ record }) => {
  const totalMonth = {};
  if (record.earnings?.length) {
    record.earnings.forEach((earning) => {
      if (totalMonth[MonthNames[earning.month]]) {
        totalMonth[MonthNames[earning.month]].coachIncome += (+earning.coachFee || +earning.amount);
        totalMonth[MonthNames[earning.month]].adminIncome += +earning.adminFee;
        totalMonth[MonthNames[earning.month]].total += +earning.adminFee + (+earning.coachFee || +earning.amount);
      } else {
        totalMonth[MonthNames[earning.month]] = {
          coachIncome: (+earning.coachFee || +earning.amount),
          adminIncome: +earning.adminFee,
          total: +earning.adminFee + (+earning.coachFee || +earning.amount),
          monthIndex: earning.month
        };
      }
    });
  }

  const currentMonth = new Date().getMonth() + 1
  const nMonths = 12
  const diff = nMonths - currentMonth

  const sortedMonths = Object.keys(totalMonth).map(key => (
    { monthName: key, ...totalMonth[key] }
  )).sort((a, b) => (b.monthIndex + diff) % (nMonths + 1) - (a.monthIndex + diff) % (nMonths + 1));

  if (sortedMonths.length) {
    return (
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                <TableCell>Total Income</TableCell>
                <TableCell>Coach Income</TableCell>
                <TableCell>System Income</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedMonths.map((month, i) => {
                return (
                  <TableRow hover key={i}>
                    <TableCell>{month.monthName}</TableCell>
                    <TableCell>{month.total}</TableCell>
                    <TableCell>{month.coachIncome}</TableCell>
                    <TableCell>{month.adminIncome}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
  return <ListItemText>This coach doesn't have incomes</ListItemText>;
};

const Dummy = (props) => {
  const choices = props?.choices || props?.input?.value || [];
  return <SelectArrayInput label="Specialities" {...props} choices={choices} />;
};

export const CoachEdit = (props) => (
  <Edit title={<CoachTitle />} {...props}>
    <TabbedForm>
      <FormTab label="personal info">
        <ImageField label="Profile Image" source="profileImage.url" className="profile-image" />
        <TextInput disabled label="Email" source="user.email" />
        <TextInput source="fullName" />
        <TextInput source="phone" />
        <TextInput source="idNumber" />
        <TextInput source="address" />
        <SelectInput
          source="status"
          choices={[
            { id: 'APPROVED', name: 'APPROVED' },
            { id: 'DECLINED', name: 'DECLINED' },
          ]}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.status === 'DECLINED' && <RichTextInput source="message" {...rest} />
          }
        </FormDataConsumer>
      </FormTab>
      <FormTab label="professional info">
        <NumberInput source="experience" />
        <ReferenceArrayInput reference="speciality" source="specialities">
          <Dummy />
        </ReferenceArrayInput>
        <TextInput source="playlist" />
        <TextInput source="meal" />
        <TextInput multiline source="about" />
      </FormTab>
      <FormTab label="diplomas">
        <ArrayField source="diplomas">
          <Datagrid style={{ width: 'auto' }}>
            <TextField source="name" />
            <ArrayField source="files">
              <Datagrid>
                <FileField title="filename" label="File" source="url" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
        <TextInput source="diplomaName" />
        <FileInput multiple label="Upload Files" source="uploadDiplomaFiles">
          <FileField title="filename" label="File" source="url" />
        </FileInput>
      </FormTab>
      <FormTab label="Financial">
        <NumberInput source="financial.fee" label="Fee" />
        <NumberInput source="financial.monthlyPayment" label="Monthly Price" />
        <EarningsList />
      </FormTab>
      <FormTab label="Trainees">
        <TraineeList />
      </FormTab>
    </TabbedForm>
  </Edit>
);
