import React from 'react';
import {
  List,
  Datagrid,
  ImageField,
} from 'react-admin';

export const IconList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <ImageField label="Icons" source="file.url" sortable={false} />
    </Datagrid>
  </List>
);
