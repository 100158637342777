import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import { LANGUAGES } from '../services/constants';

export const SpecialityList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source={LANGUAGES.EN} />
      <TextField source={LANGUAGES.HE} />
      <EditButton />
    </Datagrid>
  </List>
);
