import { getGroupsForAdminQuery, getGroupForAdminQuery } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getGroupsForAdminQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page,
        },
      },
    });
    return { data: data.getGroupsForAdmin.groups, total: data.getGroupsForAdmin.total };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getGroupForAdminQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: data.getGroupForAdmin };
  },
};

export default dataProvider;
