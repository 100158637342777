import React, { useState, useCallback } from 'react';
import { List, Datagrid, TextField, DateField, ReferenceField, EditButton, useRefresh } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Typography,
  Button,
  TextField as MUITextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { removeGroupMutation } from '@witness/graphql';

const useStyles = makeStyles(() =>
  createStyles({
    removeInputBlock: {
      marginTop: 12,
      display: 'flex',
      flexDirection: 'column',
    },
    removeHint: {
      textAlign: 'center',
    },
    removeInput: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
    },
  }),
);

const TYPE_TEXT = 'CANCEL_GROUP';

const EndDateField = ({ record }) => {
  if (record) {
    const beginningDate = new Date(record.coachProgram.beginningDate)
    return <div>{new Date(beginningDate.setDate(beginningDate.getDate() + record.programLength)).toLocaleDateString()}</div>
  }
}

const ParticipantsField = ({ record }) => {
  if (record) {
    return `${record.coachProgram.participants.length}/${record.participants}`
  }
}

const GroupAvatarField = ({ record }) => {
  return (
    <div>
      {record.groupImage ? (
        <Avatar src={`${record.groupImage.url}?size=32x32`} />
      ) : (
        <Avatar />
      )}
    </div>
  )
}

const DeleteButton = ({ record }) => {
  const [currentGroup, setGroup] = useState(null);
  const [text, setText] = useState('');
  const [removeGroup, { loading }] = useMutation(removeGroupMutation);
  const refresh = useRefresh();
  const handleCancel = useCallback(async () => {
    await removeGroup({
      variables: {
        record: {
          uid: currentGroup?.id,
        },
      },
    });
    refresh()
    setGroup(null);
  }, [removeGroup, currentGroup, refresh]);
  const classes = useStyles();
  return (
    <div>
      <Button onClick={() => setGroup(record)} type="button">
        CANCEL
      </Button>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={!!currentGroup}
      >
        <DialogTitle id="simple-dialog-title" variant="secondary">
          Deleting a group
        </DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to cancel this group? Keep in mind that you may have to return money to the trainees in this group.</Typography>
          <div className={classes.removeInputBlock}>
            <Typography className={classes.removeHint}>Type "{TYPE_TEXT}" to proceed</Typography>
            <MUITextField
              label="Type here"
              value={text}
              onChange={(e) => setText(e.target.value)}
              variant="outlined"
              size="small"
              className={classes.removeInput}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroup(null)} type="button">
            CANCEL
          </Button>
          <Button
            onClick={handleCancel}
            type="button"
            color="primary"
            variant="contained"
            disabled={text !== TYPE_TEXT || loading}
          >
            REMOVE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const GroupList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid>
      <GroupAvatarField label="Group Image" />
      <TextField source="name" sortable={false} />
      <ReferenceField label="Coach" reference="coach" source="coach.id">
        <TextField source="fullName" />
      </ReferenceField>
      <ParticipantsField label="Participants" />
      <DateField label="Start Date" source="coachProgram.beginningDate" sortable={false} />
      <EndDateField label="End Date" />
      <TextField label="Code" source="code.digits" />
      <EditButton label="Details" />
      <DeleteButton label='Action' />
    </Datagrid>
  </List>
);
