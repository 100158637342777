import * as React from 'react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListContextProvider,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
  useListContext,
} from 'react-admin';
import { Divider, Tabs, Tab } from '@material-ui/core';

const tabs = [
  { id: 'PENDING', name: 'PENDING' },
  { id: 'APPROVED', name: 'APPROVED' },
  { id: 'DECLINED', name: 'DECLINED' },
  { id: 'CANCELLED', name: 'CANCELLED' },
];

const TabbedDatagrid = props => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const [pending, setPending] = useState([]);
  const [approved, setApproved] = useState([]);
  const [declined, setDeclined] = useState([]);
  const [cancelled, setCancelled] = useState([]);

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case 'PENDING':
          setPending(ids);
          break;
        case 'APPROVED':
          setApproved(ids);
          break;
        case 'DECLINED':
          setDeclined(ids)
          break
        case 'CANCELLED':
          setCancelled(ids);
          break;
        default:
          break
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  const PriceField = ({ record }) => {
    if (record && record.monthlySum) {
      return <div>{parseInt(record.monthlySum * record.programLength / 30)}</div>
    }
    if (record && record.code && record.code.group && record.code.group.price) {
      return <div>{parseInt(record.code.group.price * record.code.group.programLength / 30)}</div>
    }
    return <div>0</div>
  }

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map(choice => (
          <Tab
            key={choice.id}
            label={choice.name}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        {filterValues.status === 'PENDING' && (
          <ListContextProvider
            value={{ ...listContext, ids: pending }}
          >
            <Datagrid {...props} optimized>
              <DateField source="createdAt" sortable={false} />
              <TextField label="Trainee" source="trainee.fullName" sortable={false} />
              <ReferenceField label="Coach" reference="coach" source="coach.id">
                <TextField source="fullName" />
              </ReferenceField>
              <TextField source="programLength" sortable={false} />
              <PriceField label="Total Price" />
              <TextField label="Coupon" source="code.digits" sortable={false} />
              <TextField source="type" sortable={false} />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'APPROVED' && (
          <ListContextProvider
            value={{ ...listContext, ids: approved }}
          >
            <Datagrid {...props} optimized>
              <DateField source="createdAt" sortable={false} />
              <TextField label="Trainee" source="trainee.fullName" sortable={false} />
              <ReferenceField label="Coach" reference="coach" source="coach.id">
                <TextField source="fullName" />
              </ReferenceField>
              <TextField source="programLength" sortable={false} />
              <PriceField label="Total Price" />
              <TextField label="Coupon" source="code.digits" sortable={false} />
              <TextField source="type" sortable={false} />
              <TextField label="Status" source="coachProgram.status" sortable={false} />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'DECLINED' && (
          <ListContextProvider
            value={{ ...listContext, ids: declined }}
          >
            <Datagrid {...props} optimized>
              <DateField source="createdAt" sortable={false} />
              <TextField label="Trainee" source="trainee.fullName" sortable={false} />
              <ReferenceField label="Coach" reference="coach" source="coach.id">
                <TextField source="fullName" />
              </ReferenceField>
              <TextField source="programLength" sortable={false} />
              <PriceField label="Total Price" />
              <TextField label="Coupon" source="code.digits" sortable={false} />
              <TextField source="type" sortable={false} />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'CANCELLED' && (
          <ListContextProvider
            value={{ ...listContext, ids: cancelled }}
          >
            <Datagrid {...props} optimized>
              <DateField source="createdAt" sortable={false} />
              <TextField label="Trainee" source="trainee.fullName" sortable={false} />
              <ReferenceField label="Coach" reference="coach" source="coach.id">
                <TextField source="fullName" />
              </ReferenceField>
              <TextField source="programLength" sortable={false} />
              <PriceField label="Total Price" />
              <TextField label="Coupon" source="code.digits" sortable={false} />
              <TextField source="type" sortable={false} />
              <TextField label="Status" source="coachProgram.status" sortable={false} />
            </Datagrid>
          </ListContextProvider>
        )}
      </div>
    </Fragment>
  );
};

const ProgramFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search By Trainee" source="traineeSearchName" alwaysOn />
    <TextInput label="Search By Coach" source="coachSearchName" alwaysOn />
  </Filter>
);

export const ProgramList = props => (
  <List {...props} filterDefaultValues={{ status: 'PENDING' }} bulkActionButtons={false} filters={<ProgramFilter />} >
    <TabbedDatagrid />
  </List>
);
