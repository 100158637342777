import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  FileInput,
  FileField,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from 'react-admin';
import { COACH, SPECIALITY } from '../resources';

const percentageParser = v => {
  if (Number(v) < 1) return 1
  if (Number(v) > 100) return 100
  return Number(v)
}

export const OrganisationCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="address" />
      <TextInput source="email" />
      <NumberInput min={1} max={100} parse={percentageParser} source="adminFee" />
      <NumberInput min={1} max={100} parse={percentageParser} source="coachFee" />
      <ReferenceArrayInput source="specialities" reference={SPECIALITY}>
        <AutocompleteArrayInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="coaches" reference={COACH} filter={{ status: 'APPROVED' }}>
        <AutocompleteArrayInput optionText="fullName" />
      </ReferenceArrayInput>
      <FileInput source="image">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);
