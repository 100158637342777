import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import CardWithIcon from './CardWithIcon';

const NewTrainees = ({ trainees = [], nb }) => {
  const classes = useStyles();

  return (
    <CardWithIcon
      to="/trainee"
      icon={CustomerIcon}
      title="New Trainees"
      subtitle={nb}
    >
      <List>
        {trainees.map((trainee) => (
          <ListItem
            key={trainee.id}
            button
            component={Link}
            to={`/trainee/${trainee.id}`}
          >
            <ListItemAvatar>
              {trainee.avatar ? (
                <Avatar src={`${trainee.avatar.url}?size=32x32`} />
              ) : (
                <Avatar />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={trainee.fullName}
            />
          </ListItem>
        ))}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button
        className={classes.link}
        component={Link}
        to="/trainee"
        size="small"
        color="primary"
      >
        <Box p={1} className={classes.linkContent}>
          All Trainees
        </Box>
      </Button>
    </CardWithIcon>
  );
};

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: 'hidden',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}));

export default NewTrainees;