import React, { useCallback, useMemo, useState } from 'react';
import {
  Edit,
  TabbedForm,
  TextField,
  ReferenceField,
  FormTab,
  useRefresh,
  ImageField,
} from 'react-admin';
import {
  ListItemText,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField as MUITextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { cancelProgramByAdminMutation } from '@witness/graphql';
import { calculateRemainingPrice } from '../dataProvider/utils';

const useStyles = makeStyles(() =>
  createStyles({
    removeInputBlock: {
      marginTop: 12,
      display: 'flex',
      flexDirection: 'column',
    },
    removeHint: {
      textAlign: 'center',
    },
    removeInput: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
    },
  }),
);

const GroupTitle = ({ record }) => {
  return <span>Group: {record ? record.name : ''}</span>;
};

const TYPE_TEXT = 'CANCEL_PROGRAM';

const ParticipantList = ({ record }) => {
  const [currentProgram, setProgram] = useState(null);
  const [text, setText] = useState('');
  const programPrice = useMemo(() => {
    return calculateRemainingPrice(currentProgram);
  }, [currentProgram]);

  const classes = useStyles();

  const [cancelProgram, { loading }] = useMutation(cancelProgramByAdminMutation);
  const refresh = useRefresh();

  const handleCancel = useCallback(async () => {
    await cancelProgram({
      variables: {
        record: {
          uid: currentProgram?.id,
        },
      },
    });
    refresh()
    setProgram(null);
  }, [cancelProgram, currentProgram, refresh]);

  if (
    record.coachProgram &&
    record.coachProgram.participants &&
    record.coachProgram.participants.length &&
    record.coachProgram.programRequest &&
    record.coachProgram.programRequest.length
  ) {
    return (
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Trainee Name</TableCell>
                <TableCell>Age</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Height</TableCell>
                <TableCell>Weight</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record.coachProgram.participants.map((trainee) => {
                const program = record.coachProgram.programRequest.filter(program => program && program.trainee.uid === trainee.uid && program.status === 'APPROVED')[0]
                return (
                  <TableRow hover key={trainee.uid}>
                    <TableCell component={Link} to={`/trainee/${trainee.uid}`}>
                      {trainee.fullName}
                    </TableCell>
                    <TableCell>{trainee.age}</TableCell>
                    <TableCell>{trainee.gender}</TableCell>
                    <TableCell>{trainee.height}</TableCell>
                    <TableCell>{trainee.weight}</TableCell>
                    <TableCell>
                      {program.status === 'APPROVED' && (
                        <Button onClick={() => setProgram(program)} type="button">
                          CANCEL
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          aria-labelledby="simple-dialog-title"
          open={!!currentProgram}
        >
          <DialogTitle id="simple-dialog-title" variant="secondary">
            Removing a program
          </DialogTitle>
          <DialogContent>
            <Typography>You will need to return back ₪{programPrice} to this trainee</Typography>
            <div className={classes.removeInputBlock}>
              <Typography className={classes.removeHint}>Type "{TYPE_TEXT}" to proceed</Typography>
              <MUITextField
                label="Type here"
                value={text}
                onChange={(e) => setText(e.target.value)}
                variant="outlined"
                size="small"
                className={classes.removeInput}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setProgram(null)} type="button">
              CANCEL
            </Button>
            <Button
              onClick={handleCancel}
              type="button"
              color="primary"
              variant="contained"
              disabled={text !== TYPE_TEXT || loading}
            >
              REMOVE
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    );
  }
  return <ListItemText>This group doesn't have participants</ListItemText>;
};

export const GroupEdit = (props) => (
  <Edit title={<GroupTitle />} {...props}>
    <TabbedForm>
      <FormTab label="personal info">
        <ImageField label="Profile Image" source="groupImage.url" className="profile-image" />
        <TextField source="name" />
        <ReferenceField label="Coach" reference="coach" source="coach.id">
          <TextField source="fullName" />
        </ReferenceField>
      </FormTab>
      <FormTab label="Participants">
        <ParticipantList />
      </FormTab>
    </TabbedForm>
  </Edit>
);
