import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { MenuItemLink, DashboardMenuItem } from 'react-admin';
import TuneIcon from '@material-ui/icons/Tune';
import UserIcon from '@material-ui/icons/Group';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import ApartmentIcon from '@material-ui/icons/Apartment';
import StorageIcon from '@material-ui/icons/Storage';
import ReceiptIcon from '@material-ui/icons/Receipt';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import RateReviewIcon from '@material-ui/icons/RateReview';
import MoodIcon from '@material-ui/icons/Mood';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PaymentIcon from '@material-ui/icons/Payment';
import MoneyIcon from '@material-ui/icons/Money';
import {
  COACH,
  SPECIALITY,
  EXERCISE,
  GOAL,
  TRAININGTYPE,
  CALENDARMESSAGE,
  ICON,
  PAYMENT,
  COUPON,
  EARNING,
  PROGRAM,
  TRAINEE,
  ORGANISATION,
  GROUP,
} from '../resources';

import SubMenu from './SubMenu';

const Menu = ({ onMenuClick, dense = true }) => {
  const [state, setState] = useState({
    menuSystemPreferences: true,
  });
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box mt={6}>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <MenuItemLink
        to={`/${ORGANISATION}`}
        primaryText="Organisations"
        leftIcon={<ApartmentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/${COACH}`}
        primaryText={'Coaches'}
        leftIcon={<UserIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/${TRAINEE}`}
        primaryText={'Trainees'}
        leftIcon={<UserIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/${PROGRAM}`}
        primaryText={'Programs'}
        leftIcon={<InsertInvitationIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/${GROUP}`}
        primaryText={'Groups'}
        leftIcon={<GroupWorkIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/${PAYMENT}`}
        primaryText={'Payments'}
        leftIcon={<PaymentIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {(process.env.REACT_APP_API_URL === 'https://api-staging.witnesstofitness.com/graphql' ||
        process.env.REACT_APP_API_URL === 'http://localhost:8000/graphql') && (
          <MenuItemLink
            to={`/${EARNING}`}
            primaryText={'Earnings'}
            leftIcon={<MoneyIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        )}
      <SubMenu
        handleToggle={() => handleToggle('menuSystemPreferences')}
        isOpen={state.menuSystemPreferences}
        sidebarIsOpen={open}
        name="System Preferences"
        dense={dense}
        icon={<TuneIcon />}
      >
        <MenuItemLink
          to={`/${GOAL}`}
          primaryText={'Goals'}
          leftIcon={<LocationSearchingIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${ICON}`}
          primaryText={'Icons'}
          leftIcon={<MoodIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${CALENDARMESSAGE}`}
          primaryText={'Calendar Messages'}
          leftIcon={<RateReviewIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${EXERCISE}`}
          primaryText={'Exercises'}
          leftIcon={<FitnessCenterIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${SPECIALITY}`}
          primaryText={'Specialities'}
          leftIcon={<StorageIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${TRAININGTYPE}`}
          primaryText={'Training Types'}
          leftIcon={<StorageIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${COUPON}`}
          primaryText={'Coupons'}
          leftIcon={<ReceiptIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
