import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
}));

const LatestPrograms = ({ programs = [] }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="Latest Programs" />
      <List dense={true}>
        {programs.map(program => (
          <ListItem
            key={program.id}
          >
            <ListItemAvatar>
              {program.trainee.avatar ? (
                <Avatar
                  src={`${program.trainee.avatar.url}?size=32x32`}
                />
              ) : (
                <Avatar />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={program.trainee.fullName}
              secondary={`${program.trainingTypes[0].name} ${program.programLength / 30} months program - Coach ${program.coach.fullName}`}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default LatestPrograms;