import {
  getCouponsQuery,
  getCouponQuery,
  createOrUpdateAdminCodeMutation,
  deleteAdminCodeMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getCouponsQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page,
        },
      },
    });
    return { data: data.getCoupons.coupons, total: data.getCoupons.total };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getCouponQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: data.getCoupon };
  },
  create: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: createOrUpdateAdminCodeMutation,
      variables: {
        record: {
          title: params.data.title,
          description: params.data.description,
          discountType: params.data.discountType,
          amount: params.data.amount,
          available: params.data.available,
          validUntil: params.data.validUntil,
        },
      },
    });
    return { data: data.createOrUpdateAdminCode };
  },
  update: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: createOrUpdateAdminCodeMutation,
      variables: {
        record: {
          uid: params.id,
          title: params.data.title,
          description: params.data.description,
          discountType: params.data.discountType,
          amount: params.data.amount,
          available: params.data.available,
          validUntil: params.data.validUntil,
        },
      },
    });
    return { data: data.createOrUpdateAdminCode };
  },
  delete: async (params) => {
    return await apolloClient.mutate({
      mutation: deleteAdminCodeMutation,
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
  },
};

export default dataProvider;
