import React, {
  useState,
  useEffect,
} from 'react';

import MonthlyRevenue from './MonthlyRevenue';
import ActivePrograms from './ActivePrograms';
import LatestPrograms from './LatestPrograms';
import PendingCoaches from './PendingCoaches';
import NewTrainees from './NewTrainees';
import ProgramChart from './ProgramChart'
import dataProvider from './dataProvider'

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;

const Dashboard = () => {
  const [state, setState] = useState({});
  const fetchOrders = async () => {
    const { data } = await dataProvider.getList();
    setState(() => ({
      nbActivePrograms: data.nbActivePrograms,
      nbPendingCoaches: data.nbPendingCoaches,
      nbNewTrainees: data.nbNewTrainees,
      pendingCoaches: data.pendingCoaches,
      newTrainees: data.newTrainees,
      newProgramRequests: data.newProgramRequests,
      latestPrograms: data.latestPrograms,
      monthlyIncomes: data.monthlyIncomes
    }));
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const {
    nbActivePrograms,
    nbPendingCoaches,
    nbNewTrainees,
    latestPrograms,
    pendingCoaches,
    newTrainees,
    monthlyIncomes,
    newProgramRequests,
  } = state;
  return (
    <>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <MonthlyRevenue value={monthlyIncomes || 0} />
            <Spacer />
            <ActivePrograms value={nbActivePrograms} />
          </div>
          <div style={styles.singleCol}>
            <ProgramChart programs={newProgramRequests} />
          </div>
          <div style={styles.singleCol}>
            <LatestPrograms
              programs={latestPrograms}
            />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <PendingCoaches
              nb={nbPendingCoaches}
              coaches={pendingCoaches}
            />
            <Spacer />
            <NewTrainees
              nb={nbNewTrainees}
              trainees={newTrainees}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;