import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin';

const IconRenderer = ({ record }) => <img alt="" src={`${record.file.url}`} />

export const CalendarMessageCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="text" />
      <ReferenceInput perPage={50} label="Icon" source="icon" reference="icon">
        <SelectInput optionText={<IconRenderer />} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);