import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UserIcon from '@material-ui/icons/Group';
import { Link } from 'react-router-dom';

import CardWithIcon from './CardWithIcon';

const PendingCoaches = ({ coaches = [], nb }) => {
  const classes = useStyles();
  return (
    <CardWithIcon
      to="/coach"
      icon={UserIcon}
      title="Pending Coaches"
      subtitle={nb}
    >
      <List>
        {coaches.map((coach) => (
          <ListItem
            key={coach.id}
            button
            component={Link}
            to={`/coach/${coach.id}`}
          >
            <ListItemAvatar>
              <Avatar
                src={`${coach.profileImage.url}?size=32x32`}
                className={classes.avatar}
              />
            </ListItemAvatar>
            <ListItemText
              primary={coach.fullName}
            />
          </ListItem>
        ))}
      </List>
      <Box flexGrow="1">&nbsp;</Box>
      <Button
        className={classes.link}
        component={Link}
        to="/coach"
        size="small"
        color="primary"
      >
        <Box p={1} className={classes.linkContent}>
          All Coaches
        </Box>
      </Button>
    </CardWithIcon>
  );
};

const useStyles = makeStyles(theme => ({
  avatar: {
    background: theme.palette.background.paper,
  },
  listItemText: {
    overflowY: 'hidden',
    height: '4em',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}));

export default PendingCoaches;