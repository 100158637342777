import React from 'react';
import ReactDOM from 'react-dom';
import { Admin, Resource } from 'react-admin';
import { ApolloProvider } from '@apollo/react-hooks';

import { authProvider } from './auth/authProvider';
import Login from './auth/Login';
import * as serviceWorker from './serviceWorker';
import { dataProvider } from './dataProvider';
import apolloClient from './apollo';
import { Layout } from './layout';
import './App.css';
import {
  COACH,
  SPECIALITY,
  EXERCISE,
  GOAL,
  TRAININGTYPE,
  CALENDARMESSAGE,
  ICON,
  PAYMENT,
  COUPON,
  EARNING,
  PROGRAM,
  TRAINEE,
  ORGANISATION,
  GROUP,
} from './resources';
import { IconList } from './icon';
import { PaymentList } from './payment';
import { CouponList, CouponEdit, CouponCreate } from './coupon';
import { CoachList, CoachEdit } from './coach';
import { TraineeList, TraineeEdit } from './trainee';
import { GoalList, GoalEdit, GoalCreate } from './goal';
import { ExerciseList, ExerciseCreate, ExerciseEdit } from './exercise';
import { SpecialityList, SpecialityEdit, SpecialityCreate } from './speciality';
import { TrainingTypeList, TrainingTypeEdit, TrainingTypeCreate } from './training-type';
import {
  CalendarMessageList,
  CalendarMessageCreate,
  CalendarMessageEdit,
} from './calendar-message';
import { EarningList } from './earning';
import { ProgramList } from './program';
import { Dashboard } from './dashboard';
import { OrganisationList, OrganisationCreate, OrganisationEdit } from './organisation';
import { GroupList, GroupEdit } from './group';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Admin
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      dashboard={Dashboard}
    >
      {(permissions) =>
        permissions === 'admin' && [
          <Resource
            name={ORGANISATION}
            list={OrganisationList}
            create={OrganisationCreate}
            edit={OrganisationEdit}
          />,
          <Resource name={COACH} list={CoachList} edit={CoachEdit} />,
          <Resource name={TRAINEE} list={TraineeList} edit={TraineeEdit} />,
          <Resource name={PROGRAM} list={ProgramList} />,
          <Resource name={GROUP} list={GroupList} edit={GroupEdit} />,
          <Resource
            name={SPECIALITY}
            list={SpecialityList}
            create={SpecialityCreate}
            edit={SpecialityEdit}
          />,
          <Resource
            name={EXERCISE}
            list={ExerciseList}
            create={ExerciseCreate}
            edit={ExerciseEdit}
          />,
          <Resource name={GOAL} list={GoalList} create={GoalCreate} edit={GoalEdit} />,
          <Resource
            name={TRAININGTYPE}
            list={TrainingTypeList}
            create={TrainingTypeCreate}
            edit={TrainingTypeEdit}
          />,
          <Resource
            name={CALENDARMESSAGE}
            list={CalendarMessageList}
            create={CalendarMessageCreate}
            edit={CalendarMessageEdit}
          />,
          <Resource name={ICON} list={IconList} />,
          <Resource name={PAYMENT} list={PaymentList} />,
          <Resource name={COUPON} list={CouponList} edit={CouponEdit} create={CouponCreate} />,
          <Resource name={EARNING} list={EarningList} />,
        ]
      }
    </Admin>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
