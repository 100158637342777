import React from 'react';
import { Edit, TextInput, SimpleForm, ImageInput, ImageField } from 'react-admin';

import { LANGUAGES } from '../services/constants';

const SpecialityTitle = ({ record }) => {
  return <span>Speciality: {record ? record[LANGUAGES.EN] : ''}</span>;
};

export const SpecialityEdit = (props) => (
  <Edit title={<SpecialityTitle />} {...props}>
    <SimpleForm>
      <TextInput source={LANGUAGES.EN} />
      <TextInput source={LANGUAGES.HE} />

      <ImageInput source="icon">
        <ImageField source="url" title="filename" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);
