import React from 'react';
import { Edit, TextInput, SimpleForm } from 'react-admin';

import { LANGUAGES } from '../services/constants';

const GoalTitle = ({ record }) => {
  return <span>Goal: {record ? record[LANGUAGES.EN] : ''}</span>;
};

export const GoalEdit = (props) => (
  <Edit title={<GoalTitle />} {...props}>
    <SimpleForm>
      <TextInput source={LANGUAGES.EN} />
      <TextInput source={LANGUAGES.HE} />
    </SimpleForm>
  </Edit>
);
