import React, { useCallback, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  useDataProvider,
} from 'react-admin';
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import dataProvider from './dataProvider';
import { TRAINEE } from '../resources';

const TraineeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const TraineeAvatar = ({ record }) => {
  return (
    <div>{record.avatar ? <Avatar src={`${record.avatar.url}?size=32x32`} /> : <Avatar />}</div>
  );
};

const HasActiveProgram = ({ record }) => {
  const activeProgram = record.programs.filter(program => program?.coachProgram?.status === 'ONGOING')
  return <div>{`${!!activeProgram.length}`}</div>;
};

const ProgramStatus = ({ record }) => {
  let status
  const activeProgram = record.programs.filter(program => program?.coachProgram?.status === 'ONGOING')
  if (activeProgram.length) {
    status = activeProgram[0].coachProgram.status
  } else if (record.programs.length && record.programs[0].coachProgram) {
    status = record.programs[0].coachProgram.status
  }
  status = status === 'DECLINED' ? 'CANCELED' : status
  return <div>{status}</div>;
};

const BlockTraineeButton = ({ record }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const provider = useDataProvider(TRAINEE, { id: record.id });

  const handleBlock = useCallback(async () => {
    setLoading(false);
    dataProvider
      .blockTrainee(record.id)
      .then(() => {
        provider.getOne(TRAINEE, { id: record.id });
        setOpen(false);
      })
      .catch((err) => { })
      .finally(() => setLoading(false));
  }, [record, provider]);

  const handleOpen = useCallback((e) => {
    e.stopPropagation();
    setOpen(true);
  }, []);

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <>
      <Button color="primary" variant="contained" disabled={record.blocked} onClick={handleOpen}>
        {record.blocked ? 'Blocked' : 'Block'}
      </Button>
      {open && (
        <Dialog aria-labelledby="simple-dialog-title" open onClick={(e) => e.stopPropagation()}>
          <DialogTitle id="simple-dialog-title" variant="secondary">
            Block {record.fullName}?
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button onClick={handleClose} type="button">
              CANCEL
            </Button>
            <Button
              onClick={handleBlock}
              type="button"
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Block
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export const TraineeList = (props) => (
  <List {...props} filters={<TraineeFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TraineeAvatar label="Profile" />
      <TextField source="fullName" sortable={false} />
      <DateField label="Registered" source="createDate" sortable={false} />
      <HasActiveProgram label="Active Program" />
      <ProgramStatus label="Program Status" />
      <TextField source="gender" sortable={false} />
      <TextField source="age" sortable={false} />
      <BlockTraineeButton label="Actions" />
    </Datagrid>
  </List>
);
