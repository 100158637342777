import { getDashboardDataQuery } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async () => {
    const { data } = await apolloClient.query({
      query: getDashboardDataQuery,
      fetchPolicy: 'network-only'
    });
    return { data: data.getDashboardData };
  }
};

export default dataProvider;
