import { getProgramPaymentsQuery } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getProgramPaymentsQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page,
          coachName: params.filter.coachName,
          programStatus: params.filter.programStatus,
          paymentStatus: params.filter.paymentStatus,
        },
      },
    });
    return { data: data.getProgramPayments.payments, total: data.getProgramPayments.total };
  },
};

export default dataProvider;
