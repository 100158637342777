import { getEarningsQuery } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getEarningsQuery,
      fetchPolicy: 'network-only',
      variables: {
        filter: {
          month: params.filter?.month,
          year: params.filter?.year,
        },
        pagination: {},
      },
    });
    return { data: data.getEarnings?.items || [], total: data.getEarnings?.total || 0 };
  },
};

export default dataProvider;
