import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { LANGUAGES } from '../services/constants';

export const TrainingTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source={LANGUAGES.EN} />
      <TextInput source={LANGUAGES.HE} />
      <ReferenceInput label="Specialities" source="speciality" reference="speciality">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
