import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ImageField,
} from 'react-admin';

export const CalendarMessageList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <ImageField label="Icons" source="icon.file.url" />
      <TextField source="text" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);
