import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

import { LANGUAGES } from '../services/constants';

export const GoalCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source={LANGUAGES.EN} />
      <TextInput source={LANGUAGES.HE} />
    </SimpleForm>
  </Create>
);
