import {
  getAllCoachesQuery,
  getCoachesForOrganisationQuery,
  editCoachFromAdminMutation,
  getCoachByIdQuery,
  deleteCoachMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getAllCoachesQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page,
          searchText: params.filter.q,
          status: params.filter.status,
        },
      },
    });
    return { data: data.getAllCoaches.coaches, total: data.getAllCoaches.total };
  },
  getMany: async () => {
    const { data } = await apolloClient.query({
      query: getCoachesForOrganisationQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getCoachesForOrganisation };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getCoachByIdQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: { ...data.coach, specialities: data?.coach?.specialities?.map((a) => a.id) } };
  },
  update: async (params) => {
    const currFinancial = params.data.financial;
    const prevFinancial = params.previousData.financial;
    let diplomaFiles;
    let financial;
    if (
      currFinancial.fee !== prevFinancial.fee ||
      currFinancial.monthlyPayment !== prevFinancial.monthlyPayment
    ) {
      financial = {
        fee: currFinancial.fee,
        monthly: currFinancial.monthlyPayment,
      };
    }
    if (params.data.diplomaName && params.data.uploadDiplomaFiles) {
      diplomaFiles = {
        files: params.data.uploadDiplomaFiles.map((file) => file.rawFile),
        diplomaName: params.data.diplomaName,
      };
    }
    const { data } = await apolloClient.mutate({
      mutation: editCoachFromAdminMutation,
      variables: {
        record: {
          sendMail: params.data.status !== params.previousData.status,
          status: params.data.status,
          uid: params.id,
          fullName: params.data.fullName,
          idNumber: params.data.idNumber,
          phone: params.data.phone,
          address: params.data.address,
          experience: params.data.experience,
          playlist: params.data.playlist,
          meal: params.data.meal,
          about: params.data.about,
          message: params.data.message ? params.data.message : null,
          specialities: params.data.specialities || [],
        },
        financial,
        diplomaFiles,
      },
    });

    return { data: data.editCoachFromAdmin };
  },
  delete: async (params) => {
    return await apolloClient.mutate({
      mutation: deleteCoachMutation,
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
  },
};

export default dataProvider;
