import {
  getOrganisationsQuery,
  getOrganisationQuery,
  createOrganisationMutation,
  updateOrganisationMutation,
  removeOrganisationMutation,
  addCoachesToOrganisationMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getOrganisationsQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getOrganisations.items, total: data.getOrganisations.count };
  },
  getMany: async (params) => {
    const { data } = await apolloClient.query({
      query: getOrganisationsQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getOrganisations.items, total: data.getOrganisations.count };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getOrganisationQuery,
      fetchPolicy: 'network-only',
      variables: {
        uid: params.id,
      },
    });

    const org = Object.assign({}, data.getOrganisation, {
      specialityIds: Array.isArray(data.getOrganisation?.specialities)
        ? data.getOrganisation.specialities.map((s) => s.uid)
        : [],
      coachIds: Array.isArray(data.getOrganisation?.coaches)
        ? data.getOrganisation.coaches.map((c) => c.uid)
        : [],
    });
    return { data: org };
  },
  create: async (params) => {
    const {
      name,
      description,
      email,
      adminFee,
      coachFee,
      image,
      specialities,
      coaches,
      address,
    } = params.data;
    const { data } = await apolloClient.mutate({
      mutation: createOrganisationMutation,
      variables: {
        record: {
          name,
          description,
          email,
          address,
          adminFee: parseInt(adminFee),
          coachFee: parseInt(coachFee),
          image: image.rawFile,
          specialitiesUids: specialities,
        },
      },
    });

    await apolloClient.mutate({
      mutation: addCoachesToOrganisationMutation,
      variables: {
        record: {
          uid: data.createOrganisation?.uid,
          coachesUids: coaches,
        },
      },
    });

    return { data: data.createOrganisation };
  },
  update: async (params) => {
    const {
      uid,
      name,
      description,
      adminFee,
      coachFee,
      image,
      coachIds,
      specialityIds,
      address,
    } = params.data;

    const { data } = await apolloClient.mutate({
      mutation: updateOrganisationMutation,
      variables: {
        record: {
          uid,
          name,
          description,
          address,
          adminFee: parseInt(adminFee),
          coachFee: parseInt(coachFee),
          image: image?.rawFile,
          specialitiesUids: specialityIds,
        },
      },
    });

    await apolloClient.mutate({
      mutation: addCoachesToOrganisationMutation,
      variables: {
        record: {
          uid,
          coachesUids: coachIds,
        },
      },
    });

    return { data: data.updateOrganisation };
  },
  delete: (params) => {
    return apolloClient.mutate({
      mutation: removeOrganisationMutation,
      variables: {
        uid: params.id,
      },
    });
  },
};

export default dataProvider;
