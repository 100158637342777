import {
  getSpecialitiesQuery,
  getSpecialityQuery,
  createSpecialityMutation,
  editSpecialityMutation,
  deleteSpecialityMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';
import { serializeItem, translateParams } from '../dataProvider/utils';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getSpecialitiesQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getSpecialities.map(serializeItem), total: data.getSpecialities.length };
  },
  getMany: async (params) => {
    const { data } = await apolloClient.query({
      query: getSpecialitiesQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getSpecialities.map(serializeItem), total: data.getSpecialities.length };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getSpecialityQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: serializeItem(data.getSpeciality) };
  },
  create: async (params) => {
    const translations = translateParams(params);

    const { data } = await apolloClient.mutate({
      mutation: createSpecialityMutation,
      variables: {
        record: {
          translations,
          icon: params.data.icon?.rawFile,
        },
      },
    });
    return { data: serializeItem(data.createSpeciality) };
  },
  update: async (params) => {
    const translations = translateParams(params);

    const { data } = await apolloClient.mutate({
      mutation: editSpecialityMutation,
      variables: {
        record: {
          uid: params.id,
          translations,
          icon: params.data.icon?.rawFile,
        },
      },
    });
    return { data: serializeItem(data.editSpeciality) };
  },
  delete: async (params) => {
    return await apolloClient.mutate({
      mutation: deleteSpecialityMutation,
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
  },
};

export default dataProvider;
