import * as React from 'react';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';


import CardWithIcon from './CardWithIcon';

const NbActivePrograms = ({ value }) => {
  return (
    <CardWithIcon
      to="/program"
      icon={InsertInvitationIcon}
      title="Active Programs"
      subtitle={value}
    />
  );
};

export default NbActivePrograms;