import { getIconsQuery } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getIconsQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination?.perPage,
          skip: params.pagination?.page,
        },
      },
    });
    return { data: data.getIcons.icons, total: data.getIcons.total };
  },
  getMany: async (params) => {
    const { data } = await apolloClient.query({
      query: getIconsQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination?.perPage,
          skip: params.pagination?.page,
        },
      },
    });
    return { data: data.getIcons.icons, total: data.getIcons.total };
  },
};

export default dataProvider;
