import {
  getGoalsQuery,
  getGoalQuery,
  createGoalMutation,
  editGoalMutation,
  deleteGoalMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';
import { serializeItem, translateParams } from '../dataProvider/utils';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getGoalsQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getGoals.map(serializeItem), total: data.getGoals.length };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getGoalQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: serializeItem(data.getGoal) };
  },
  create: async (params) => {
    const translations = translateParams(params);

    const { data } = await apolloClient.mutate({
      mutation: createGoalMutation,
      variables: {
        record: {
          translations,
        },
      },
    });

    return { data: serializeItem(data.createGoal) };
  },
  update: async (params) => {
    const translations = translateParams(params);

    const { data } = await apolloClient.mutate({
      mutation: editGoalMutation,
      variables: {
        record: {
          uid: params.id,
          translations: translations,
        },
      },
    });
    return { data: serializeItem(data.editGoal) };
  },
  delete: (params) => {
    return apolloClient.mutate({
      mutation: deleteGoalMutation,
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
  },
};

export default dataProvider;
