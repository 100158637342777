import React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, DateInput, SelectInput, FormDataConsumer } from 'react-admin';

const currentDate = new Date()

const dateParser = v => {
  const date = new Date()
  const d = new Date(v)
  if (isNaN(d)) return
  if (d < date) return
  return d
};

const percentageParser = v => {
  if (Number(v) < 1) return 1
  if (Number(v) > 100) return 100
  return Number(v)
}

const amountParser = v => {
  if (Number(v) < 0) return 0
  return Number(v)
}

export const CouponCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="title" />
      <TextInput source="description" />
      <SelectInput source="discountType" choices={[
        { id: 'AMOUNT', name: 'Amount' },
        { id: 'PERCENTAGE', name: 'Percentage' },
      ]} />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.discountType === 'PERCENTAGE'
            ? <NumberInput min={1} max={100} parse={percentageParser} source="amount" {...rest} />
            : <NumberInput min={0} parse={amountParser} source="amount" {...rest} />
        }
      </FormDataConsumer>
      <NumberInput source="available" />
      <DateInput defaultValue={currentDate.setFullYear(currentDate.getFullYear() + 1)} parse={dateParser} source="validUntil" />
    </SimpleForm>
  </Create>
);