import { getAllTraineesQuery, getTraineeByIdQuery, blockTraineeMutation } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getAllTraineesQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page,
          searchText: params.filter.q,
        },
      },
    });
    return { data: data.getAllTrainees.trainees, total: data.getAllTrainees.total };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getTraineeByIdQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: data.getTraineeById };
  },
  blockTrainee: (traineeUid) => {
    return apolloClient.mutate({
      mutation: blockTraineeMutation,
      variables: {
        traineeUid,
      },
    });
  },
};

export default dataProvider;
