import { getProgramsForAdminQuery } from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getProgramsForAdminQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page,
          status: params.filter.status,
          traineeSearchName: params.filter.traineeSearchName,
          coachSearchName: params.filter.coachSearchName,
        },
      },
    });
    return { data: data.getProgramsForAdmin.programs, total: data.getProgramsForAdmin.total };
  },
};

export default dataProvider;
