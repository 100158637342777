import { signInMutation } from '@witness/graphql';
import decodeJwt from 'jwt-decode';

import apolloClient from '../apollo';

// const authProvider = {
//   login: params => Promise.resolve(),
//   logout: params => Promise.resolve(),
//   checkAuth: params => Promise.resolve(),
//   checkError: error => Promise.resolve(),
//   getPermissions: params => Promise.resolve(),
//   getIdentity: () => Promise.resolve(),
// };

export const authProvider = {
  login: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: signInMutation,
      variables: {
        record: {
          email: params.username,
          password: params.password
        }
      }
    });
    const token = data.signIn.token
    const decodedToken = decodeJwt(token)
    localStorage.setItem('token', token)
    localStorage.setItem('permissions', decodedToken.role.name)
    return Promise.resolve()
  },
  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('permissions')
    return Promise.resolve()
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/no-access' }),
  getPermissions: () => {
    const role = localStorage.getItem('permissions')
    return role === 'admin' ? Promise.resolve(role) : Promise.reject({ redirectTo: '/no-access' })
  }
};
