import React from 'react';
import { Create, SimpleForm, TextInput, ImageInput, ImageField } from 'react-admin';
import { LANGUAGES } from '../services/constants';

export const SpecialityCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source={LANGUAGES.EN} />
      <TextInput source={LANGUAGES.HE} />

      <ImageInput source="icon">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
