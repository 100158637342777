import React from 'react';
import {
  Edit,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';

const ExerciseTitle = ({ record }) => {
  return <span>Exercise: {record ? record.title : ''}</span>;
};


export const ExerciseEdit = (props) => {
  return (
      <Edit title={<ExerciseTitle />} {...props}>
        <SimpleForm>
          <TextInput source="title" />
          <SelectInput source="type" choices={[
            { id: 'UPPERBODY', name: 'Upper Body' },
            { id: 'LOWERBODY', name: 'Lower Body' },
            { id: 'CORE', name: 'Core' },
            { id: 'MULTI', name: 'Multi' },
            { id: 'STRETCH', name: 'Stretch' },
            { id: 'PILATESR', name: 'Pilates R' },
          ]} />
          <FormDataConsumer>
            {({ formData, ...rest }) => <SelectInput style={{width: '256px'}} disabled={formData.type !== 'PILATESR'} source="category" choices={[
            { id: 'WARMPUP', name: 'Warm Up' },
            { id: 'ARTICULATION', name: 'Articulation' },
            { id: 'CORE', name: 'Core' },
            { id: 'EXTENSION', name: 'Extension' },
            { id: 'ARMS', name: 'Arms' },
            { id: 'LOWERBODY', name: 'Lower Body' },
            { id: 'BOOTY', name: 'Booty' },
            { id: 'TOTALBODY', name: 'Total Body' },
            { id: 'SPINEMOBILITY', name: 'Spine Mobility' },
            { id: 'FLOW', name: 'Flow' },
          ]} />}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => <TextInput style={{width: '256px'}} disabled={formData.type !== 'PILATESR'} source="setting" title={"Setting"} />}
          </FormDataConsumer>
          <RadioButtonGroupInput source="gender" choices={[
            { id: 'MALE', name: 'Male' },
            { id: 'FEMALE', name: 'Female' },
          ]} />
          <FileInput source="video">
            <FileField source="url" title="filename" />
          </FileInput>
          <TextInput fullWidth source="youtubeVideoId" title={"Youtube"} />
        </SimpleForm>
      </Edit>
  );
}
