import {
  getCalendarMessagesQuery,
  getCalendarMessageQuery,
  createCalendarMessageMutation,
  deleteCalendarMessageMutation,
  editCalendarMessageMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getCalendarMessagesQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          limit: params.pagination.perPage,
          skip: params.pagination.page
        }
      }
    });
    return { data: data.getCalendarMessages.messages, total: data.getCalendarMessages.total };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getCalendarMessageQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id
        }
      }
    })
    return { data: data.getCalendarMessage }
  },
  create: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: createCalendarMessageMutation,
      variables: {
        record: {
          text: params.data.text,
          icon: params.data.icon,
        }
      }
    })
    return { data: data.createCalendarMessage }
  },
  update: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: editCalendarMessageMutation,
      variables: {
        record: {
          uid: params.id,
          text: params.data.text,
          icon: params.data.icon.id
        }
      }
    })
    return { data: data.editCalendarMessage }
  },
  delete: async (params) => {
    return await apolloClient.mutate({
      mutation: deleteCalendarMessageMutation,
      variables: {
        record: {
          uid: params.id
        }
      }
    })
  }
};

export default dataProvider;
