import {
  getTrainingTypesQuery,
  getTrainingTypeQuery,
  createTrainingTypeMutation,
  editTrainingTypeMutation,
  deleteTrainingTypeMutation,
} from '@witness/graphql';

import apolloClient from '../apollo';
import { serializeItem, translateParams } from '../dataProvider/utils';

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getTrainingTypesQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getTrainingTypes.map(serializeItem), total: data.getTrainingTypes.length };
  },
  getMany: async (params) => {
    const { data } = await apolloClient.query({
      query: getTrainingTypesQuery,
      fetchPolicy: 'network-only',
    });
    return { data: data.getTrainingTypes.map(serializeItem), total: data.getTrainingTypes.length };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getTrainingTypeQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
    return { data: serializeItem(data.getTrainingType) };
  },
  create: async (params) => {
    const translations = translateParams(params);

    const { data } = await apolloClient.mutate({
      mutation: createTrainingTypeMutation,
      variables: {
        record: {
          translations,
          speciality: params.data.speciality,
        },
      },
    });
    return { data: data.createTrainingType };
  },
  update: async (params) => {
    const translations = translateParams(params);

    const { data } = await apolloClient.mutate({
      mutation: editTrainingTypeMutation,
      variables: {
        record: {
          uid: params.id,
          translations,
          speciality: params.data.speciality.id,
        },
      },
    });
    return { data: data.editTrainingType };
  },
  delete: (params) => {
    return apolloClient.mutate({
      mutation: deleteTrainingTypeMutation,
      variables: {
        record: {
          uid: params.id,
        },
      },
    });
  },
};

export default dataProvider;
