export const COACH = 'coach';
export const TRAINEE = 'trainee';
export const SPECIALITY = 'speciality';
export const EXERCISE = 'exercise';
export const GOAL = 'goal';
export const TRAININGTYPE = 'training type';
export const CALENDARMESSAGE = 'calendar message';
export const ICON = 'icon';
export const PAYMENT = 'payment';
export const COUPON = 'coupon';
export const EARNING = 'earning';
export const PROGRAM = 'program';
export const ORGANISATION = 'organisation';
export const GROUP = 'group';
