import {
  createExerciseMutation,
  deleteExerciseMutation,
  editExerciseMutation,
  getExerciseByIdQuery,
  getExercisesPaginatedQuery,
} from '@witness/graphql';

import apolloClient from '../apollo';

function convertYoutubeLinkToEmbedded(url) {
  console.log(url)
  if (!url) {
    return null;
  }

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  console.log("match:", match);

  return match && match[2].length === 11 ? match[2] : null;
}

const dataProvider = {
  getList: async (params) => {
    const { data } = await apolloClient.query({
      query: getExercisesPaginatedQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          isGlobal: true,
          category: params.filter.category,
          searchText: params.filter.q,
          type: params.filter.type,
          sortField: params.sort.field,
          sortOrder: params.sort.order,
          limit: params.pagination.perPage,
          skip: params.pagination.page,
        }
      }
    });
    return { data: data.getExercisesPaginated.exercises, total: data.getExercisesPaginated.total };
  },
  getOne: async (params) => {
    const { data } = await apolloClient.query({
      query: getExerciseByIdQuery,
      fetchPolicy: 'network-only',
      variables: {
        record: {
          id: params.id
        }
      }
    })
    return { data: data.getExerciseById }
  },
  create: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: createExerciseMutation,
      variables: {
        record: {
          title: params.data.title,
          type: params.data.type,
          gender: params.data.gender,
          video: params.data.video?.rawFile,
          category: params.data.category,
          setting: params.data.setting,
          youtubeVideoId: convertYoutubeLinkToEmbedded(params.data.youtubeVideoId)
        }
      }
    })
    return { data: data.createExercise }
  },
  update: async (params) => {
    const { data } = await apolloClient.mutate({
      mutation: editExerciseMutation,
      variables: {
        record: {
          id: params.id,
          title: params.data.title,
          type: params.data.type,
          gender: params.data.gender,
          video: params.data.video?.rawFile,
          category: params.data.category,
          setting: params.data.setting,
          youtubeVideoId: convertYoutubeLinkToEmbedded(params.data.youtubeVideoId)
        }
      }
    })
    return { data: data.editExercise }
  },
  delete: async (params) => {
    return await apolloClient.mutate({
      mutation: deleteExerciseMutation,
      variables: {
        record: {
          id: params.id
        }
      }
    })
  }
};

export default dataProvider;
