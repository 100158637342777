import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import { Avatar } from '@material-ui/core';

const OrgAvatar = ({ record }) => {
  return (
    <div>
      {record.image ? (
        <Avatar src={`${record.image.compressedUrl || record.image.url}?size=32x32`} />
      ) : (
        <Avatar />
      )}
    </div>
  );
};

export const OrganisationList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <OrgAvatar label="Avatar" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="adminFee" />
      <TextField source="coachFee" />

      <ArrayField source="specialities">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>

      <ArrayField source="coaches">
        <SingleFieldList linkType={false}>
          <ChipField source="fullName" />
        </SingleFieldList>
      </ArrayField>

      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
