import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, SelectInput, DateField } from 'react-admin';

const PaymentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search By Coach" source="coachName" alwaysOn />
    <SelectInput
      source="programStatus"
      choices={[
        { id: 'PENDING', name: 'Pending' },
        { id: 'APPROVED', name: 'Approved' },
        { id: 'DECLINED', name: 'Declined' },
        { id: 'CANCELLED', name: 'Cancelled' },
      ]}
      alwaysOn
    />
    <SelectInput
      source="paymentStatus"
      choices={[
        { id: 'PENDING', name: 'Pending' },
        { id: 'SUCCESS', name: 'Success' },
        { id: 'FAIL', name: 'Fail' },
      ]}
      alwaysOn
    />
  </Filter>
);

export const PaymentList = (props) => (
  <List {...props} filters={<PaymentFilter />} bulkActionButtons={false}>
    <Datagrid>
      <TextField label="Program" source="program.trainingTypes[0].name" sortable={false} />
      <TextField label="Coach" source="coach.fullName" sortable={false} />
      <TextField label="Trainee" source="trainee.fullName" sortable={false} />
      <TextField label="Program Status" source="program.status" sortable={false} />
      <TextField label="Program Length" source="program.programLength" sortable={false} />
      <DateField source="transactionDate" sortable={false} />
      <TextField label="Total Amount" source="sum" sortable={false} />
      <TextField label="Payment Status" source="status" sortable={false} />
    </Datagrid>
  </List>
);
