import React from 'react';
import { Edit, TextInput, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';
import { LANGUAGES } from '../services/constants';

const TrainingTypeTitle = ({ record }) => {
  return <span>Training Type: {record ? record.name : ''}</span>;
};

export const TrainingTypeEdit = (props) => (
  <Edit title={<TrainingTypeTitle />} {...props}>
    <SimpleForm>
      <TextInput source={LANGUAGES.EN} />
      <TextInput source={LANGUAGES.HE} />
      <ReferenceInput label="Specialities" source="speciality.id" reference="speciality">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
