import React, { useMemo } from 'react';
import { List, Datagrid, TextField, Filter, SelectInput } from 'react-admin';
import { MonthNames } from '../dataProvider/utils';

const MonthField = ({ source, record = {} }) => <span>{MonthNames[record[source]]}</span>;

const EarningFilter = (props) => {
  const monthItems = useMemo(() => MonthNames.reduce((acc, curr, i) => {
    if (i) {
      acc.push({ id: i, name: curr })
    }
    return acc
  }, []), []);
  const yearItems = useMemo(() => {
    const thisYear = new Date().getFullYear();

    const years = [];
    for (let i = thisYear - 5; i < thisYear + 2; i++) {
      years.push({ id: i, name: i + '' });
    }

    return years;
  }, []);

  return (
    <Filter {...props}>
      <SelectInput alwaysOn source="month" choices={monthItems} />
      <SelectInput alwaysOn source="year" choices={yearItems} />
    </Filter>
  );
};

export const EarningList = (props) => (
  <List {...props} filters={<EarningFilter />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="traineeName" sortable={false} />
      <TextField source="amount" sortable={false} />
      <MonthField source="month" sortable={false} />
      <TextField source="year" sortable={false} />
      <TextField source="programName" sortable={false} />
    </Datagrid>
  </List>
);
