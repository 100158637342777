import React from 'react';
import { List, Datagrid, TextField, EditButton, DateField } from 'react-admin';

export const CouponList = (props) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="description" />
      <TextField source="discountType" />
      <TextField source="amount" />
      <TextField source="usage" />
      <TextField source="available" />
      <TextField source="code.digits" label="Coupon #" />
      <DateField source="validUntil" />
      <EditButton />
    </Datagrid>
  </List>
);
