import React from 'react';
import {
  Edit,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

const CalendarMessageTitle = ({ record }) => {
  return <span>Calendar Message: {record ? record.text : ''}</span>;
};

const IconRenderer = ({ record }) => <img alt="" src={`${record.file.url}`} />

export const CalendarMessageEdit = (props) => (
  <Edit title={<CalendarMessageTitle />} {...props}>
    <SimpleForm>
      <TextInput source="text" />
      <ReferenceInput perPage={50} label="Icon" source="icon.id" reference="icon">
        <SelectInput optionText={<IconRenderer />} />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);