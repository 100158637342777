import React from 'react';
import {
  Datagrid, EditButton, Filter, List, SelectInput, TextField, TextInput, UrlField
} from 'react-admin';

const ExerciseFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput source="type" choices={[
      { id: 'UPPERBODY', name: 'Upper Body' },
      { id: 'LOWERBODY', name: 'Lower Body' },
      { id: 'CORE', name: 'Core' },
      { id: 'MULTI', name: 'Multi' },
      { id: 'STRETCH', name: 'Stretch' },
      { id: 'PILATESR', name: 'Pilates R' },
    ]} />
    <SelectInput source="category" choices={[
      { id: 'WARMPUP', name: 'Warm Up' },
      { id: 'ARTICULATION', name: 'Articulation' },
      { id: 'CORE', name: 'Core' },
      { id: 'EXTENSION', name: 'Extension' },
      { id: 'ARMS', name: 'Arms' },
      { id: 'LOWERBODY', name: 'Lower Body' },
      { id: 'BOOTY', name: 'Booty' },
      { id: 'TOTALBODY', name: 'Total Body' },
      { id: 'SPINEMOBILITY', name: 'Spine Mobility' },
      { id: 'FLOW', name: 'Flow' },
    ]} />
  </Filter>
);

export const ExerciseList = (props) => (
  <List {...props} filters={<ExerciseFilter />} bulkActionButtons={false} sort={{ field: 'title', order: 'ASC' }}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="type" sortable={false} />
      <TextField source="gender" sortable={false} />
      <TextField source="setting" sortable={false} />
      <TextField source="category" sortable={false} />
      <UrlField label="Video" source="video.url" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);
